import { create } from "zustand";
import axios from "axios";
export const useStore = create((set) => ({
  point: {},
  addPoint: (point, num) =>
    set((state) => ({
      point: { ...state.point, [num]: point },
    })),
  
  totalClick : axios.get("https://backendhanderler.onrender.com/getCurrentTotalMy").then((res) => {
    set({ totalClick: res.data.data });
  }).catch((err) => {}),
  setTotalClick: (value) => set({ totalClick: value }),
  info: {
    firstName: "",
    bagColor: "",
    url: undefined,
  },

  setInfo: (_info) =>
    set((state) => ({
      info: { ...state.info, ..._info },
    })),

  playAnimation: false,
  setplayAnimation: (value) => set({ playAnimation: value }),
}));
