import Phaser from "phaser";
import axios from "axios";

export class Playscene extends Phaser.Scene {
  constructor() {
    super("playscene");
    this.tick = false;
    this.limit = 100;
    this.sequnce = 0;
    this.currentScene = 1;
    this.startTime = Date.now();
    this.gameDuration = 30;
    this.speedRatioClock = 60 / this.gameDuration;
    window.startGame = false;
    window.container = [];
    this.clickCountText = 0
    this.currentTime = Date.now();
    this.animating = false;
    this.animationBagOutLine = false;
    this.currentFrame = [0,1,2];
    this.sellectedFrame = this.currentFrame[Math.floor(Math.random() * this.currentFrame.length)]
    this.count5sec = Date.now();
    this.endGame = false;
    this.initTotalClick = 0;
  }

  init() {}

  preload() {}

  create() {
    this.width = this.sys.game.config.width;
    this.height = this.sys.game.config.height;

    const light = this.lights.addLight(0, 0, this.width );
    light.setIntensity(1);
    light.setPosition(this.width / 2, this.height * 0.1);
    this.lights.enable().setAmbientColor(0xfff);


    this.cloud = this.add.image(-this.width , this.height * 0.5, "cloud");
    this.cloud.setDisplaySize(this.height, this.height);
    this.cloud.setDepth(1500);

    this.tweens.add({
      targets: this.cloud,
      x: this.width * 2,
      duration: 1800,
      repeat: 0,
    });


    this.bg = this.add.image(this.width / 2, this.height / 2, "bg");
    this.bg.setDisplaySize(this.height, this.height);
    this.bg.setDepth(-2);
    window.limit = this.limit;

    // bar clock
    let _hbar = this.width * 0.1;
    let _wbar = _hbar * 3;
    let originx = this.width * 0.67 + _wbar * 0.5;
    let originy = this.height * 0.58 + _hbar * 0.5;
    this.bar = this.add.image(originx, originy, "bar");
    this.bar.setDisplaySize(_wbar, _hbar);
    this.bar.setDepth(1000);
    this.clickCountText = this.add.text(
      originx + _wbar * 0.15,
      originy,
      "00:" + this.gameDuration,
      {
        fontFamily: "HelveticaLTPro-Bold",
        fontSize: _hbar * 0.5,
        fontWeight: "bold",
        color: "white",
      }
    );
    this.clickCountText.setOrigin(0.5);
    this.clickCountText.setDepth(1001);

    this.clock = this.add.sprite(originx - _wbar * 0.35, originy, "clock");
    this.clock.setDisplaySize(_hbar * 1.1, _hbar * 1.1);
    this.clock.setDepth(1000);
    // bar clock
    let _hbarC = this.width * 0.1;
    let _wbarC = _hbarC * 3;
    let originxC = this.width * 0.03 + _wbarC * 0.5;
    let originyC = this.height * 0.58 + _hbarC * 0.5;
    this.barC = this.add.image(originxC, originyC, "bar");
    this.barC.setDisplaySize(_wbarC, _hbarC);
    this.barC.setDepth(1000);

    this.cs = this.add.sprite(originxC - _wbarC * 0.28, originyC, "cs");
    this.cs.setDisplaySize(_hbarC * 1.2, _hbarC * 1.2);
    this.cs.setDepth(1000);
    this.cs.anims.play("cs");
    // enddddddddddddddddddddddd
    this.countText = this.add.text(originxC + _wbarC * 0.15, originyC, "0", {
      fontFamily: "HelveticaLTPro-Bold",
      fontSize: _hbar * 0.5,
      fontWeight: "bold",
      color: "white",
    });
    this.countText.setOrigin(0.5);
    this.countText.setDepth(1001);

    // bag arr
    this.bagArr = [];
    this.cloudArr = [];

    // scene 2
    let _h = this.width * 3;
    let _w = _h * 1.174;
    this.sun = this.add.sprite((this.width / 2) - _w *0.01, -_h * 0.035, "sun");
    this.sun.setDisplaySize(_w, _h);
    this.sun.setDepth(10);

    this.sun2 = this.add.sprite((this.width / 2) - _w *0.01, -_h * 0.035, "sun_without_glow");
    this.sun2.setDisplaySize(_w, _h);
    this.sun2.setDepth(11);


    // this.sun.setPipeline("Light2D");

    let sunW = this.sun.width;
    this.scaleRatio = _w * 1.05/ sunW;

    this._invert = false;

    let oriLangX = this.width * 0.5;
    let oriLangY = this.height - this.height * 0.266 * 0.6 * 0.4;
    this.land = this.add.sprite(oriLangX, oriLangY, "land");
    this.land.setDisplaySize(this.height * 0.62, this.height * 0.62 * 0.246);
    this.land.setDepth(400);
    let bagH = this.height * 0.18;
    let bagW = bagH * 1.62;
    let originXbag = this.width * 0.5;
    let originYbag = oriLangY - bagH * 0.73;
    this.bag = this.add.sprite(originXbag, originYbag, "bags");
    this.bag.setDisplaySize(bagW, bagH);
    this.bag.setDepth(300);

    this.neoncircle = this.add.image(
      originXbag,
      originYbag + bagH * 0.2,
      "neon_circle"
    );
    this.neoncircle.setDepth(1400);
    this.neoncircle.setDisplaySize(bagH * 1.2, bagH * 1.2);

    this.cneon = this.add.image(originXbag, originYbag + bagH * 0.01, "cneon");
    this.cneon.setDepth(this.neoncircle.depth - 1);
    this.cneon.setDisplaySize(bagW, bagH);

    // hand tap
    this.hantap = this.add.sprite(
      originXbag + bagW * 0.2,
      originYbag + bagH * 0.4,
      "hantap"
    );
    this.hantap.setDisplaySize(bagH * 0.8, bagH * 0.8);
    this.hantap.setDepth(this.neoncircle.depth + 1);
    this.hantap.setAngle(-20);
    this.hantap.anims.play("hantap");

    // add text
    this.gamePlayText = this.add
      .text(
        this.width * 0.5,
        this.height * 0.45,
        `TAP AS MANY TABBYS`,
        {
          fontFamily: "HelveticaLTPro-Bold",
          fontSize: this.height * 0.035,
          fontWeight: "bold",
          color: "white",
        }
      )
      .setOrigin(0.5)
      .setDepth(this.neoncircle.depth + 1);

      this.gamePlayText2 = this.add
      .text(
        this.width * 0.5,
        this.height * 0.45 + this.gamePlayText.height,
        `WITHIN 30 SECONDS`,
        {
          fontFamily: "HelveticaLTPro-Bold",
          fontSize: this.height * 0.035,
          fontWeight: "bold",
          color: "white",
          textAlign: "center",
        }
      )
      .setOrigin(0.5)
      .setDepth(this.neoncircle.depth + 1);


    

    // create a rect yellow alpha 0.5 to cover the screen
    this.rect = this.add.rectangle(
      this.width / 2,
      this.height / 2,
      this.width,
      this.height,
      0xffff00
    );

    this.rect.setAlpha(1);
    this.rect.setDepth(this.neoncircle.depth - 2);
    this.rect.setAlpha(0);
    this.tweens.add({
      targets: this.neoncircle,
      scale: 0.3,
      duration: 800,
      yoyo: true,
      repeat: -1,
    });

    // logo
    let logoX = this.width * 0.5;
    let logoY = this.height * 0.055;
    this.logo = this.add.sprite(logoX, logoY, "logo");
    this.logo.setDisplaySize(this.height * 0.04 * 3.67, this.height * 0.04);
    this.logo.setDepth(1000);

    this.totalTapText = this.add.text(
      logoX,
      logoY + this.height * 0.064,
      "NATIONWIDE: " + this.initTotalClick.toString(),
      {
        fontFamily: "HelveticaLTPro-Bold",
        fontSize: this.height * 0.015,
        fontWeight: "bold",
        color: "white",
      }
    ).setDepth(1000).setOrigin(0.5);

    axios.get("https://backendhanderler.onrender.com/getCurrentTotalMy").then((res) => {
      this.totalTapText.setText("NATIONWIDE: " + res.data.data);
    });

        // text this.totalTapText this.totalTapText
    this.totalTapText2 = this.add.text(
          logoX,
          logoY + this.height * 0.042,
          "TOTAL TABBYS SENT",
          {
            fontFamily: "HelveticaLTPro-Bold",
            fontSize: this.height * 0.015,
            fontWeight: "bold",
            color: "white",
          }
        ).setDepth(1000).setOrigin(0.5);
    

    [
      "barC",
      "cs",
      "countText",
      "bar",
      "clock",
      "countText",
      "clickCountText",
      "sun",
      "sun2",
      "totalTapText",
      "totalTapText2"
    ].forEach((item) => {
      if(item === "sun"){
        this[item].setAlpha(0);
      }
      else{
        this[item].setAlpha(0);
      }
    });

    this.input.on("pointerdown", (pointer) => {
      if(window.startGame === false){
        [ "gamePlayText", "neoncircle", "cneon", "hantap" , "gamePlayText2"].forEach(
          (item) => {
            this.tweens.add({
              targets: this[item],
              alpha: 0,
              duration: 500,
              onComplete: () => {
                this[item].destroy();
              },
            });
          }
        );
        
        // barC cs countText bar
        if (this.barC.alpha === 0) {
          [
            "barC",
            "cs",
            "countText",
            "bar",
            "clock",
            "countText",
            "clickCountText",
            "sun",
            "sun2",
            "totalTapText",
            "totalTapText2"
          ].forEach((item) => {
  
            if(item === "sun"){
              this.tweens.add({
                targets: this[item],
                alpha: 0.2,
                duration: 500,
              });
            }
            else{
              this.tweens.add({
                targets: this[item],
                alpha: 1,
                duration: 500,
              });
              }
      
          });
        }  
      }


      window.startGame = true;
      // play audio
      this.sound.play("click");

      if(window.startGame === true && this.endGame === false){
          // cs, barC countText bar clock
          this.createBag();

          if(!this.animationBagOutLine){
            this.animationBagOutLine = true;
            this.tweens.add({
                targets: this.bag,
                y : `+=${this.height * 0.003}`,
                yoyo: true,
                duration : 50,
                onComplete: () => {
                  this.animationBagOutLine = false;
                }
            });
          }
      
          if(Math.random() > 0.5){
            this.createBag();
          }
        }
    });
  }

  createCloud() {
    this.cloud = ["cloud1_" , "cloud2_"];
    let _cloud = this.cloud[Math.floor(Math.random() * this.cloud.length)];
    let randomPos = 1 - Math.random()

    let font = this.add.sprite(this.width * -0.3 , this.height * randomPos , _cloud);
    let __h = this.height * 0.4 + Math.random() * 0.43 * this.height;
    let __w = __h ;

    font.setDisplaySize(__w, __h);
    font.setDepth(1);

    this.cloudArr.push({
      cloud: font,
      speed: Math.random() * 5 + 3,
      randomSpeed : Math.random() * 0.3 + 0.25,
    });
  }

  createBag() {
    this._invert = !this._invert;
    this.letter = ["bags"];
    let _letter = this.letter[Math.floor(Math.random() * this.letter.length)];
    let font = this.add.sprite(this.width / 2, this.height * 0.9, _letter);
    let __h = this.height * 0.031 + Math.random() * 0.023 * this.height
    let __w = _letter === "bags" ? __h * 1.62 : __h;

    font.setDisplaySize(__w, __h);
    font.setDepth(1);

    if (_letter !== "bags") {
      font.anims.play(_letter);
    } else {
      // let _ = Math.floor(Math.random() * 3);
      font.setFrame(this.sellectedFrame);

      // if(Math.random() < 0.08){
      // }
    }
    // randomAlpha
    let ran = Math.random();
    if(ran > 0.7){
      font.setAlpha(Math.random() * 0.5 + 0.5);
    }
    else{
      font.setAlpha(1);
    }

    this.bagArr.push({
      bag: font,
      speed: Math.random() * 200 + 150,
      invert: !this._invert,
      randomSpeed : Math.random() * 0.6 + 0.5
    });
  }

  createMeteor(originX, originY, r) {
    let quantity = Math.random() * 30;
    for (let i = 0; i < quantity; i++) {
      let rDegrees = Math.random() * 1 * Math.PI;
      let extendR = Math.random() * r * 0.5;
      const x = originX + extendR - r * 0.25 + Math.cos(rDegrees) * r;
      const y = originY + extendR + Math.sin(rDegrees) * r;

      this.meteor(x, y, originX, originY);
    }
  }

  meteor(x, y, originX, originY) {
    const meteor = this.add.image(originX, originY, "meteor");
    // add glow effect
    // let fs = meteor.preFX.addGlow()
    let _alpha = Math.random() * 0.7 + 0.3;
    meteor.setAlpha(_alpha);
    let rotationTarget = Math.atan2(y - originY, x - originX) + Math.PI;
    let _h = this.height * 0.05 * Math.random() + this.height * 0.03;
    let _w = (_h * 538) / 270;
    meteor.setAlpha(Math.random() * 0.5 + 0.3);

    meteor.setDisplaySize(_w, _h);
    meteor.setRotation(rotationTarget);
    this.tweens.add({
      targets: meteor,
      x: x,
      y: y,
      scale: 0.1,
      delay: Math.random() * 1000,
      duration: 1200,
      onComplete: () => {
        meteor.destroy();
      },
    });
  }

  async sendUpdate() {}

  update(time, delta) {
    if(Date.now() - this.currentTime > 1000 / 2){
      this.currentTime = Date.now();
      this.createCloud();
    }

    if(Date.now() - this.count5sec > 1000 * 5){
      this.count5sec = Date.now();
      this.sellectedFrame += 1;
      if(this.sellectedFrame > 2){
        this.sellectedFrame = 0;
      }

      this.bag.setFrame(this.sellectedFrame);
    }
    
    if (window.startGame) {
      if (Date.now() - this.startTime > 1000) {
        this.startTime = Date.now();
        if (this.gameDuration <= 0) {
          if (this.gameDuration === 0 && !this.endGame) {
            this.endGame = true;
        
            if (window.container.length < 1200) {
              this.totalTapText.setAlpha(0);
              this.totalTapText2.setAlpha(0);
              // timeup 
              this.timeup = this.add.image(this.width / 2, this.height / 2, "timeup");
              this.timeup.setDisplaySize(this.height * 0.4, this.height * 0.5);
              this.timeup.setAlpha(1);

              let ratio = this.height * 0.6 / this.timeup.width;
              this.tweens.add({
                targets: this.timeup,
                scale: ratio,
                duration: 1000,
                ease: "Bounce.easeOut",
                onComplete: () => {
                  window.navTo("/leaderbroad");
                },
              });
              window.captureScore = window.container;

              try{
                window.updateScore(window.container.length)
                window
                .recordGameData("dataframe", window.captureScore)
                .then((res) => {
                  axios
                    .get("https://backendhanderler.onrender.com/my", {
                      params: {
                        uuid: window.uuid,
                      },
                    })
                    .then((res) => {
                      const { data } = res;
                      const {total} = data;
                      window.setTotalClick(total);
                    });
                });
              }
              catch(e){
                window.navTo("/leaderbroad"); 
              }
            }

          }
          this.gameDuration--;
          window.startGame = false;
          this.clickCountText.setText("00:00");
    
        } else {
          this.gameDuration--;
          // this.clock play specific frame
          let _ = Math.floor(this.gameDuration * this.speedRatioClock)
          this.clock.setFrame(_);
          this.clickCountText.setText(
            "00:" + this.gameDuration.toString().padStart(2, "0")
          );
        }
      }
    }

    if (this.cloudArr.length > 0) {
      this.cloudArr.forEach((info, index) => {
        info.cloud.x += 1 * info.speed * info.randomSpeed;

        
        if (info.cloud.x > this.width * 1.3) {
          info.cloud.destroy();
          this.cloudArr.splice(index, 1);
        }
      });
    }

    // if bag length is more than 1
    if (this.bagArr.length > 0) {
      this.bagArr.forEach((info, index) => {
        info.bag.y -= this.height* 0.0007 * delta * info.randomSpeed;
        info.bag.x = info.invert
          ? Math.sin(info.bag.y / info.speed) * this.width * 0.1 +
            this.width / 2
          : Math.cos(info.bag.y / info.speed) * this.width * 0.1 +
            this.width / 2;

        
        if (info.bag.y < this.height * 0.20) {
          
          if(!this.animating){
            this.animating = true;
            this.sound.play("vibrate");
            this.tweens.add({
              targets: this.sun,
              scale: this.scaleRatio * 0.9,
              alpha : 1,
              yoyo: true,
              duration: 50,
              onComplete: () => {
                this.animating = false;
              },
            });

            this.tweens.add({
              targets: this.sun2,
              y: "+=10",
              alpha : 1,
              yoyo: true,
              duration: 50,
              onComplete: () => {
                this.animating = false;
              },
            });
          }

          info.bag.destroy();
          this.bagArr.splice(index, 1);

          this.createMeteor(
            this.width / 2,
            0,
            this.sys.game.config.width * 0.8
          );

          // delay 50 play this.sound.play("spark");
          // this.sound.play("spark");

          if(!this.endGame){
            this.countText.setText(
              (parseInt(this.countText.text) + 1).toString()
            );
  
            this.totalTapText.setText(
              "NATIONWIDE: " + (window.totalClick + parseInt(this.countText.text)).toString()
            )
            
            window.count = parseInt(this.countText.text);
            window.container.push(Date.now());  
          }
        }
      });
    }
  }
}
